@import "~@fluentui/react/dist/sass/References.scss";

body {
  margin: 0;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:12px;
  
}

main {
  padding: 10px;
}
@media only screen and (max-width: 768px) {
  body {
    font-size: $ms-font-size-12;
    margin: 0px;
    padding: 0px;
  }

  main {
    padding: 10px;
    margin: 10px;

  }
}
